<template>
    <b-card no-body>
        <b-card-body>
            <div class="d-flex justify-content-end  flex-wrap">

                <!-- filter -->
                <b-form-group

                    label-cols-sm="2"
                    label-align-sm="left"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('Type to Search')"
                        />
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter"
                                @click="filter = ''"
                            >
                                {{ $t("Clear") }}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
        </b-card-body>

        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="municipalities"
            :fields="fields"
            :filter="filter"
        >
            <template #cell(avatar)="data">
                <b-avatar :src="data.profile_image"/>
            </template>
            <template #cell(active)="data">
                <b-form-checkbox
                    :checked="data.item.is_active"
                    name="check-button"
                    switch
                    inline
                    @change="changeUserStatus(data.item.is_active,data.item.id)"
                >
                </b-form-checkbox>
            </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

            <!-- page length -->
            <b-form-group
                :label="$t('Per Page')"
                label-cols="8"
                label-align="left"
                label-size="md"
                label-for="perPageSelect"
                class="text-nowrap mb-md-0 mr-1"
            >
                <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                />
            </b-form-group>

            <!-- pagination -->
            <div>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                >
                    <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                    </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormCheckbox,
} from 'bootstrap-vue'
import BCardCode from "@core/components/b-card-code/BCardCode";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "show",
    components: {
        BFormCheckbox,
        BCard,
        BCardCode,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
    },
    data() {
        return {
            perPage: 5,
            pageOptions: [3, 5, 10],
            currentPage: 1,

            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [
                {
                    key: 'id', label: 'Id',
                },
                {
                    key: 'avatar', label: this.$t('Image'),
                },
                {key: 'first_name', label: this.$t('First Name'), sortable: true},
                {key: 'last_name', label: this.$t('Last Name'), sortable: true},
                // {key: 'post', label: 'Post', sortable: true},
                {key: 'email', label: this.$t('Email'), sortable: true},
                {key: 'phone', label: this.$t('Phone'), sortable: true},
                {key: 'created_at', label: this.$t('Created At'), sortable: true},
                {key: 'active', label: this.$t('Active'), sortable: true},
            ],
        }
    },
    computed: {
        municipalities() {
            return this.$store.state.municipalities.belediyeler;
        },
        totalRows() {
            return this.$store.state.municipalities.belediyeler.length;
        },
    },
    methods: {
        changeUserStatus(newValue, userId) {
            let myPayload = {new_status: !newValue, admin_id: userId};
            this.$store.dispatch("municipalities/updateUserStatus", myPayload)
                .then(res => {
                    // console.log(res.data.data[0].is_active)
                })
                .catch(err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                })
        },
    },
    created() {
        this.$store.dispatch("municipalities/getMunicipalities").then(res => {
            // console.log(res.data.data[0].is_active)
        });
    }
}
</script>

<style scoped>

</style>
